import React from "react";
import useFetch from "../useFetch.js";
import Game from "./Game.jsx";


function Scoreboard(props) {
  const url="https://cdn.espn.com/core/nfl/scoreboard?xhr=1&week="+props.week+"&year=" +props.year + "&seasontype=2"
   var teamsOnBye=[];
   var eventData=[];
   const { data: espnData, loading, error} = useFetch(url);

if (loading) return <h1> LOADING...</h1>;

if (error) console.log(error);

if(espnData){
  teamsOnBye = espnData.content.sbData.week.teamsOnBye;
  eventData = espnData.content.sbData.events;
}
  return (
    <div>
<p>Teams on Bye: {teamsOnBye ? teamsOnBye.map((team)=>{return(<span className="byeTeam">{team.displayName}</span>);}) :"None"}</p>
 {eventData && eventData.map((event, index)=>{return(<Game
     key={index}
     event={event}
  />);})
 }
    </div>
  );
}

export default Scoreboard;
