import React from "react";
/*import Select from "react-select"; */
import Scoreboard from "./Scoreboard.jsx";

function App() {
  const currentDate = new Date(); // Creates a Date object with the current date and time
  var curMonth = currentDate.getMonth();
  var curYear = currentDate.getFullYear();
  var startYear = 2003;
  if(curMonth < 6){
    curYear = curYear - 1;
  }
  var optionYear = [];
  for (var y = curYear; y >= startYear; y--) {
     optionYear.push(y);
  }

  const option18 = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18];
  const option17 = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];

  const [footballDate, setFootballDate] = React.useState({
    year: curYear,
    week: "1"
  });


  function handleChange(event){

    var {name, value} = event.target;

    setFootballDate((prevDate)=>{
      return({year: prevDate.year,
              week: prevDate.week,
              [name]: value});
    });

  }


  return (
    <div className="App">

Year: <select name="year" id="year" onChange={handleChange}>
    {optionYear.map((optionValue)=>{
        return (
          <option key={optionValue} value={optionValue}>{optionValue}</option>
        );
    })}
</select>
Week: <select name="week" id="week" onChange={handleChange}>
  {footballDate.year >= 2021 ? option18.map((optionValue)=>{return(
    <option key={optionValue} value={optionValue}>{optionValue}</option>
  )}) : option17.map((optionValue)=>{return(
    <option key={optionValue} value={optionValue}>{optionValue}</option>
  )})
}
</select>

<Scoreboard
  year={footballDate.year}
  week={footballDate.week}
/>
    </div>
  );
}

export default App;
