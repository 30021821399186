import React from "react";


function Game(props) {

  var homeTeam = props.event.competitions[0].competitors[0].team.abbreviation;
  var homeScore = props.event.competitions[0].competitors[0].score;
  const homeLogo = props.event.competitions[0].competitors[0].team.logo;
  var awayTeam = props.event.competitions[0].competitors[1].team.abbreviation;
  var awayScore = props.event.competitions[0].competitors[1].score;
  const awayLogo = props.event.competitions[0].competitors[1].team.logo;
  var homeRecord = "";
  var awayRecord = "";

  if(props.event.competitions[0].competitors[0].records){
    homeRecord = "(" + props.event.competitions[0].competitors[0].records[0].summary + ")";
  }
  if(props.event.competitions[0].competitors[1].records){
    awayRecord = "(" + props.event.competitions[0].competitors[1].records[0].summary + ")";
  }

  var footer = "";
  var status = "";
  if (props.event.status.type.name === "STATUS_SCHEDULED") {
    if(Object.keys(props.event.competitions[0].broadcasts).length > 0){
      status = props.event.status.type.shortDetail + " " + props.event.competitions[0].broadcasts[0].names[0];
    }else{
      status = props.event.status.type.shortDetail;
    }
    homeTeam = props.event.competitions[0].competitors[0].team.displayName;
    awayTeam = props.event.competitions[0].competitors[1].team.displayName;
    homeScore = "";
    awayScore = "";
    if(!(props.event.competitions[0].odds)){
      footer = "No Odds found";
    }else{
      let overUnder = props.event.competitions[0].odds[0].overUnder ? props.event.competitions[0].odds[0].overUnder : " ";
      let scoreOdds = props.event.competitions[0].odds[0].details ? props.event.competitions[0].odds[0].details : "  ";
      footer = scoreOdds + "     O/U: " + overUnder;
    }

  } else if (props.event.status.type.name === "STATUS_FINAL") {
      status = "Final";
      let utcDate = props.event.competitions[0].date;  // ISO-8601 formatted date returned from server
      let localDate = new Date(utcDate);
      footer = localDate.toDateString() + "   " + localDate.toLocaleTimeString('en-us');
  } else if (props.event.status.type.name === "STATUS_POSTPONED") {
      status = "Postponed";
      footer = "Postponed";
  } else {
    status = "Q" + props.event.status.period + "   " + props.event.status.displayClock;
  }
  return (  <div className="game">
    <div className = "status" > {status} </div>
    <ul>
    <li>
      <div className="score_details">
        <img className="logo" src={awayLogo} alt="" />
        <span className="team">{awayTeam}   <span className="record">{awayRecord}</span></span>
        <div className="score">{awayScore}</div>
      </div>
    </li>
    <li>
      <div className="score_details">
        <img className="logo" src={homeLogo} alt="" />
        <span className="team">{homeTeam}   <span className="record">{homeRecord}</span></span>
        <div className="score">{homeScore}</div>
      </div>
    </li>

    </ul>
    <div className = "footer" > {footer} </div>
    </div>
  );
}

export default Game;
